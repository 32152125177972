import Swiper from 'swiper/bundle';
import $, { css, get } from "jquery";
// import initializeOptionGalleryProductSlider from './optionGalleryProductSlider';
export default function initializeProductSlider() {
    var galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 4,
        slidesPerView: 6,
        freeMode: true,
        breakpoints: {
            0: {
                direction: 'horizontal',
            },
            993: {
                direction: 'vertical',
                spaceBetween: 4,
            },
            1367: {
                spaceBetween: 15,
                direction: 'vertical',
            }
        }
    });

    var isTransitioning = false;

    const galleryTop = new Swiper('.gallery-top', {
        slidesPerView: 1,
        grabCursor: true,
        navigation: {
            nextEl: '.swiper-button-next.product-swiper-button-next',
            prevEl: '.swiper-button-prev.product-swiper-button-prev',
        },
        thumbs: {
            swiper: galleryThumbs
        },
        on: {
            transitionStart: function () {
                isTransitioning = true;
                $('video').each(function () {
                    this.pause();
                });
            },
            transitionEnd: function () {
                isTransitioning = false;
                var activeIndex = this.activeIndex;
                var activeSlide = $('.gallery-top .swiper-slide').eq(activeIndex);                
                var activeSlideVideo = activeSlide.find('video').get(0);                
                if (activeSlideVideo !== undefined && activeSlideVideo !== null) {
                    activeSlideVideo.play();
                } else {
                    console.log("No video element found in the active slide.");
                }
            },
            init: function () {
    				/*$(".gallery-top").find(".swiper-slide").each(function(){
    					let heightEl = $(this).height()-$(".product-header").height()-$(".header").height()+150;
    					var percent = heightEl/$(this).height();    					
    					var width = $(this).width()*percent;
    					$(this).find('img').attr('height',heightEl);
    					$(this).find('img').css('height',heightEl+'px');
    					$(this).find('img').css('width',width);
    					$(this).find('img').css('margin','0 auto');
    					$(this).find('img').attr('weight',width+'px'); 
    				});*/    		
    		},
            
        }
    });
    
    let videoSlide = $(".gallery-top video");
    $(videoSlide).each(function(){
    	$(this).on("ended", function () {
    		this.pause();    		
    		//var activeIndex =  $('.gallery-top .swiper-slide').attr("aria-label");
    		//var activeIndexArr =  activeIndex.split(' /');    		
    		var activeSlide = $('.gallery-top .swiper-slide').eq(galleryTop.activeIndex+1); 
    	 	var activeSlideVideo = activeSlide.find('video').get(0);                  	 	
    	 	if (activeSlideVideo !== undefined && activeSlideVideo !== null) {
    	 		galleryTop.slideNext(500);
    	 		activeSlideVideo.play();
    	 	} else {
    	 		galleryTop.slideTo(0,500);
    	 		var activeSlide = $('.gallery-top .swiper-slide').eq(0); 
    	 		var activeSlideVideo = activeSlide.find('video').get(0);  
    	 		activeSlideVideo.play();
    	 	}    		    	
        });	
    });
    


    // Handle clicks with a delay during transition
    // $('.gallery-top').on('click', function(event) {
    //     if (isTransitioning) {
    //         event.stopPropagation();
    //         event.preventDefault();
    //         setTimeout(function() {
    //             // Process the click after a short delay
    //             $(event.target).trigger('click');
    //         }, 300); // Adjust the delay time as needed
    //     }
    // });   
}